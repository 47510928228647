import React from 'react';
import classNames from 'classnames';
import SimpleCurrencyInput from '../form/simple_currency';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import numeral from 'numeral';
import axios from 'axios';


const style = {
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: 10,
    position: 'relative',
  },
}

const SimpleCurrencyInputCustom = (props) => {
  const { onChange, name, style, value, ...other } = props;
  return (
    <SimpleCurrencyInput
      {...other}
      onInputFocus={(el) => {
        const { target } = el;
        const pos = target.value.length;
        target.setSelectionRange(pos, pos);
      }}
      style={{...style, textAlign: 'right' }}
      precision={3}
      type="tel"
      value={value}
      separator=','
      delimiter='.'
      onInputChange={onChange}
    />
  );
}

export default class UpdatePriceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productPrices: {},
      processing: true,
      error: '',
      currentPrices: {},
    };
  }

  componentDidMount() {
    this.setState({ processing: true }, () => {
      axios.get(this.props.newPriceUpdatePath)
        .then((res) => {
          const productPrices = res.data;
          this.setState({ productPrices  }, () => {
            const currentPrices = productPrices.products.reduce((acc, product) => {
              acc[product.id] = this.lastPriceForProduct(product.id);
              return acc;
            }, {});
            this.setState({ currentPrices, processing: false });
          })
        })
        .catch((err) => {
          console.log('Houve um erro durante o processamento', err);
          const message = err.response.data.errors ?
            err.response.data.errors.price.join(', ')
            : err.message;
          this.setState({ processing: false, error: message });
        });
    });
  }

  disabled = () => {
    return this.state.processing
      || Object.keys(this.state.currentPrices).length !== _.get(this.state.productPrices, 'products.length')
      || !this.state.productPrices.products.every(product => this.state.currentPrices[product.id] !== 0);
  };

  pricesDatesSorted = () => {
    return Object.keys(_.get(this.state.productPrices, 'prices', {}))
      .map(date => ({ date: moment(date), key: date }))
      .sort((a, b) => b.date - a.date);
  }

  getProductByKey = (key, productId) => {
    const prices = this.state.productPrices.prices[key] || []
    return prices.find(p => p.product_id === productId) || {};
  }

  getFromattedValueByKey = (date, productId) => {
    const product = this.getProductByKey(date, productId);
    return  numeral(parseFloat(product.price)).format('0.000');
  }

  lastPriceForProduct = (productId) => {
    const date = this.pricesDatesSorted()[0].key;
    const product = this.getProductByKey(date, productId);
    return ( product.price || 0) * 1000;
  }

  handleUpdateForm = () => {
    this.setState({ processing: true }, () => {
      const prices = this.state.productPrices.products.reduce((acc, product) => {
        const currentPrice = this.state.currentPrices[product.id] / 1000;
        acc[product.id] = numeral(currentPrice).format('0.000')
        return acc;
      }, {});

      axios.post(this.props.priceUpdatePath, { prices }).then((res) => {
        window.location = this.props.basePath;
      }).catch((err) => {
        console.log('Houve um erro durante o processamento', err);
        const message = err.response.data.errors ?
          err.response.data.errors.price.join(', ')
        : err.message;
        this.setState({ processing: false, error: message });
      });
    });
  }

  render() {
    return (
      <div className="container product_prices">
        <h1 className="main-header">
          <div className="title">
            Verifique o preço dos combustíveis
          </div>
        </h1>


        {
          this.state.processing
          ? <CircularProgress size={24} style={style.buttonProgress} />
            :
            <form>
              <table className="product_prices__table">
                <thead>
                  <tr>
                    <th className="alignleft">Combustível</th>
                    <th className="highlight">
                      Preço Atualizado - { moment().format('DD/MM hh:mm') }
                    </th>
                    { this.pricesDatesSorted().map((date, idx) => <th key={idx}>{date.date.format('DD/MM hh:mm')}</th>) }
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td colSpan="3"></td>
                  </tr>
                </tfoot>
                <tbody>
                  {
                    this.state.productPrices.products.sort((a, b) => a.position - b.position).map((product, index) => (
                      <tr key={index}>
                        <td className="alignleft nobold">{product.name}</td>
                        <td className="highlight">
                          <TextField
                            value={this.state.currentPrices[product.id] }
                            InputProps={{
                              inputComponent: SimpleCurrencyInputCustom,
                            }}
                            onChange={(value) => (
                              this.setState({
                                currentPrices: {
                                  ...this.state.currentPrices,
                                  [product.id]: value,
                                }
                              })
                            )}
                          >
                          </TextField>
                        </td>
                        {
                          this.pricesDatesSorted().map((date, idx) =>
                          <td key={idx}>{this.getFromattedValueByKey(date.key, product.id)}</td>
                          )
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <div style={style.wrapper}>
                { this.state.error && <h4 style={{ color: 'red' }}>{ this.state.error }</h4> }
                <button disabled={this.disabled()}
                  onClick={this.handleUpdateForm.bind(this)}
                  className="button--primary button--wide">
                  Atualizar Preços
                </button>
              </div>
            </form>
        }
      </div>
    );
  }
}
