import React from 'react';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import numeral from 'numeral';

const styles = {
  Checkbox : {
    color: '#00ccff',
  },
  TableCell: {
    color: '#006795',
  },
  TableFooterCell: {
    color: '#fff',
  }
};

export default class ReservationSummary extends React.Component {
  componentDidMount() {
    const backButton = document.getElementsByClassName('back-button-header')[0];
    if ( backButton ) {
      backButton.style.display = "none";
    }
  }

  handleEndReservation = () => {
    this.props.handleEndReservation();
    this.props.history.push(this.props.leavePath);
  };

  renderFooter = () => {
    const { reservation_summary=[], used_liters_summary=[], payments=[] } = this.props.summary;
    const totalCredits = payments.reduce((acc, payment) => {
      if (payment.applied_credits) {
        acc = acc + payment.applied_credits.reduce((acc_credit, credit) => (acc_credit = acc_credit + credit.value), 0);
      }
      return acc;
    }, 0);

    const totalDiscount = used_liters_summary.reduce((acc, summary) => {
      acc = acc + parseFloat(summary.total_discount.replace(',', '.'));
      return acc;
    }, 0);

    const total = reservation_summary.reduce((acc, summary) => {
      acc = acc + parseFloat(summary.value.replace(',', '.'));
      return acc;
    }, 0);

    return(
      <TableFooter>
        <TableRow className="table-footer-total">
          <TableCell colSpan="3">
            Total
          </TableCell>
          <TableCell className="alignright confirmation__footer-grand-total">
            { numeral(total + totalDiscount).format('$ 0.00') }
          </TableCell>
        </TableRow>
        { totalCredits > 0 &&
          <TableRow className="table-footer-credit confirmation__footer-credits">
            <TableCell colSpan="3"> Creditos usados </TableCell>
            <TableCell className="alignright">
              - { numeral(totalCredits).format('$ 0.00') }
            </TableCell>
          </TableRow>
        }
        { totalDiscount > 0 &&
          <TableRow className="table-footer-credit confirmation__footer-discount">
            <TableCell colSpan="3"> Desconto </TableCell>
            <TableCell className="alignright">
              - { numeral(totalDiscount).format('$ 0.00') }
            </TableCell>
          </TableRow>
        }
        <TableRow className="table-footer-total">
          <TableCell colSpan="3">
            Total Pago
          </TableCell>
          <TableCell className="alignright confirmation__footer-total">
            { numeral(total - totalCredits).format('$ 0.00') }
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }

  renderReservesRow = () => {
    const { reservation_summary=[] } = this.props.summary;
    return reservation_summary.map((summary, index) => {
      return(
        <TableRow id={index} key={index}>
          <TableCell style={styles.TableCell}>
            { summary.product }
          </TableCell>

          <TableCell style={styles.TableCell}>
            { I18n.t(`offer.kind.${summary.offer_kind}`) }
          </TableCell>

          <TableCell style={styles.TableCell} className="alignright">
            { `${summary.quantity}` }
          </TableCell>

          <TableCell  className="alignright" style={styles.TableCell}>
            { `R$ ${summary.value}` }
          </TableCell>
        </TableRow>
      );
    });
  };

  renderReservesTable = () => {
    return(
      <div>
        <div className="heading">RESUMO DO ABASTECIMENTO</div>
        <div className="content-entry">
          <div className="container">
            <div className="form-compact">
              <Table className="summary-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Combustível</TableCell>
                    <TableCell>Oferta</TableCell>
                    <TableCell numeric>Litros</TableCell>
                    <TableCell numeric>Valor (Litro)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { this.renderReservesRow() }
                </TableBody>
                { this.renderFooter() }
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="reserve">
        { this.renderReservesTable() }
        <div className="content-entry">
          <div className="container">
            <div className="form-compact">
              <button className="button--primary button--wide mui-dialog__button"
                onClick={this.handleEndReservation}>
                Finalizar atendimento
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
