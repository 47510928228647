import React from 'react';
import Countdown from 'react-countdown-now';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import moment from 'moment';

export default class ReservationCounter extends React.Component {
  state = {
    open: false,
  };

  handleOpenConfirmation = () => {
    this.setState({open: true});
  };

  handleCloseConfirmation = () => {
    this.setState({open: false});
  };

  handleExitButton = () => {
    window.location = this.props.leavePath;
  };

  renderConfirmationDialog = () => {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja sair?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseConfirmation} autoFocus color="primary">
            Não
          </Button>
          <Button onClick={this.handleExitButton} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const expiresAt = moment().add(this.props.expireInSeconds, 'seconds');
    return(
      <div className="footer-timer">
        <p>TEMPO RESTANTE DE ATENDIMENTO:</p>
        <span id="session-countdown">
          <Countdown date={expiresAt.toDate()} renderer={({minutes, seconds, completed}) => {
            if (completed) {
              window.location = '/posto';
              return null;
            } else {
              return <span>{minutes}:{seconds}</span>;
            }
          }} />
        </span>
        <p>
          <Button onClick={this.handleOpenConfirmation}>Cancelar</Button>
        </p>
        { this.renderConfirmationDialog() }
      </div>
    );
  }
}
