import React from 'react';
import CPFInput from './form/cpf_input';
import TextMask from './form/text_mask';
import QRCodeReader from './reservation/QRCodeReader';
import _ from 'lodash';
import axios from 'axios';
import numeral from 'numeral';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Phone from '@material-ui/icons/StayCurrentPortrait';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Http from '@material-ui/icons/Http';


import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#6bffff',
      main: '#00ccff',
      dark: '#009bcc',
      contrastText: '#fff',
    },
  },
});

export default class UserRegistrationFormContainer extends React.Component {
  state = {
    cpf: '',
    formatedCPF: '',
    users: [],
    userValidated: false,
    userInfo: {},
    link: '',
  };

  isDisabled = () => {
    return !(this.state.userValidated
      && !_.isEmpty(this.state.userInfo)
      && _.get(this.state, 'link.length', 0) > 0
      && _.get(this.state, 'userInfo.phone.length', 0) === 15
      && _.get(this.state, 'userInfo.birthday.length', 0) === 10
      && _.get(this.state, 'userInfo.name.length', 0) > 10);
  };

  onCPFChange = ({ cpf, formatedCPF }) => {
    this.setState({ cpf, formatedCPF, userValidated: false });
  }

  onCPFValidation = (users) => {
    const meuPostoUser = users.find(({ app }) => app === 'meu_posto');
    const [ userInfo ] = users;
    this.setState({ users, userValidated: true, meuPostoUser, userInfo: userInfo || {} });
  }

  handleProcessCheckIn = ({ token: link }) => {
    this.setState({ link });
  };

  createAndReturnToHome = () => {
    this.setState({ processing: true }, () => {
      this.createUser().then(() => {
        this.setState({ processing: false });
        window.location = this.props.reserva_base_path;
      });
    });
  };

  createAndMakeAPurchase = () => {
    this.setState({ processing: true }, () => {
      this.createUser().then(() => {
        const body = {
          check_user: {
            cpf: this.state.cpf,
            app: 'meu_posto',
            last_four_digits_phone: this.state.userInfo.phone.slice(-4),
          },
        };
        axios.post(this.props.companyFastReservationCheckInPath, body).then((res) => {
          this.setState({ processing: false });
          this.props.handleCheckInUser(res.data);
          this.props.history.push(`${this.props.basePath}/select_products`);
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Houve um erro durante o processamento', err, err.response);
          const message = err.response.data.error
            ? err.response.data.error
            : err.message;
          this.setState({ processing: false, error: message });
        });
      });
    });
  };

  createUser = () => {
    const user = {
      name: this.state.userInfo.name,
      cpf: this.state.cpf,
      birthday: this.state.userInfo.birthday,
      phone: this.state.userInfo.phone.replace(/[^\d]/g, ''),
      company_token: this.state.link,
    };
    return axios.post('/posto/reserva_rapida/cadastro_usuario', { user })
      .catch((err) => {

        let link = this.state.link; // reactivate link scan
        if (err.response.status === 422 && err.response.data.error === 'Link inválido') {
          link = '';
        }
        // eslint-disable-next-line no-console
        console.log('Houve um erro durante o processamento', err, err.response);
        const message = err.response.data.error
          ? err.response.data.error
          : err.message;

        this.setState({ processing: false, error: message, link });
        return Promise.reject(err);
      });
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="check-users__form-container js-use-data-container" id="user-register">
          <form autoComplete="off" className="simple_form new_check_user" id="new_user_register">
            <div className="mdc-card">
              <section className="mdc-card__primary">
                <h3 style={{ color: 'rgba(0, 0, 0, 0.54)' }}> 1. Digite os dados do cliente:</h3>
                <CPFInput
                  value={this.state.cpf}
                  onChange={this.onCPFChange}
                  onCPFValidation={this.onCPFValidation}
                  inputProps={{
                    fullWidth: true,
                    disabled: this.state.loading,
                    style: { marginTop: 16, marginBottom: 30 },
                    id: 'cpf',
                    label: 'CPF',
                  }}
                />
              </section>
            </div>
            <br/>
            { this.state.userValidated  &&
            <React.Fragment>
              <div className="mdc-card">
                <section className="mdc-card__primary">
                  { _.isEmpty(this.state.meuPostoUser) &&
                  <React.Fragment>
                    <h2>Novo Usuário</h2>
                    <Grid container spacing={0} justify="space-evenly">
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="user-info-name"
                          className="login-card__textfield"
                          value={this.state.userInfo.name}
                          variant="outlined"
                          onChange={({target}) => this.setState({ userInfo: { ...this.state.userInfo, name: target.value }})}
                          style={{ marginBottom: 15 }}
                          label="Nome"
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><PermIdentity/></InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="user-info-dob"
                          className="login-card__textfield"
                          value={this.state.userInfo.birthday}
                          onChange={({target}) => this.setState({ userInfo: {...this.state.userInfo, birthday: target.value.trim() }})}
                          label="Data de Nascimento"
                          maxlenght="10"
                          fullWidth
                          variant="outlined"
                          style={{ marginBottom: 15 }}
                          InputProps={{
                            inputProps: { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] },
                            inputComponent: TextMask,
                            startAdornment: <InputAdornment position="start"><CalendarToday/></InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="user-info-phonenumber"
                          className="login-card__textfield"
                          value={this.state.userInfo.phone}
                          onChange={({target}) => this.setState({ userInfo: {...this.state.userInfo,  phone: target.value.trim() }})}
                          label="Número do Celular"
                          maxlenght="15"
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: 15 }}
                          InputProps={{
                            inputProps: { mask: ['(', /\d/, /\d/, ')', ' ',/\d/,/\d/,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
                            inputComponent: TextMask,
                            startAdornment: <InputAdornment position="start"><Phone/></InputAdornment>,
                          }}
                        />
                      </Grid>
                      { !this.state.link &&
                      <QRCodeReader
                        style={{ marginTop: '35px' }}
                        videoWidth="320"
                        scanningOnStart={true}
                        onTokenValidation={this.handleProcessCheckIn} />
                      }
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="user-info-link"
                          className="login-card__textfield"
                          value={this.state.link}
                          variant="outlined"
                          style={{ marginBottom: 15 }}
                          label="QRCode Crachá"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start"><Http/></InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                  }
                  { !_.isEmpty(this.state.meuPostoUser) &&
                  <React.Fragment>
                    <h2>{this.state.meuPostoUser.name}</h2>
                    <p> Saldo atual</p>
                    <p> {numeral(_.sumBy(this.state.meuPostoUser.credits, 'value') || 0.0).format('$ 0.00')}</p>
                  </React.Fragment>
                  }
                </section>
              </div>
              { this.state.error &&
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                  <h3 style={{ color: '#c73b3b', marginTop: '10px' }}> {this.state.error} </h3>
                </Grid>
              </Grid>
              }
              <input type="button"
                disabled={this.isDisabled() || this.state.processing}
                value="Cadastrar"
                style={{ marginTop: '10px' }}
                onClick={this.createAndReturnToHome}
                className="btn button--primary button--wide"/>

              <input type="button"
                disabled={this.isDisabled() || this.state.processing}
                value="Cadastrar e Abastecer"
                onClick={this.createAndMakeAPurchase}
                style={{ marginTop: '10px' }}
                className="btn button--primary button--wide"/>

            </React.Fragment>
            }
            <input type="button"
              value="Voltar"
              onClick={this.navigateToHomePage}
              className="btn button--primary button--wide button--margin-top"/>
          </form>
        </div>
      </MuiThemeProvider>
    );
  }
}
