import React from 'react';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import numeral from 'numeral';
import axios from 'axios';


export default class ReservationOfferDialogConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dialogConfirmationOpen: false,
      error: '',
      ...this.evaluateTotals(props.offers, props.credits, props.bankBalance)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.evaluateTotals(nextProps.offers, nextProps.credits, nextProps.bankBalance, nextProps.paymentMethod));
  }

  evaluateTotals(offers, credits=[], bankBalance, paymentMethod) {
    const totalPayments = offers.reduce((sum, offer) => {
      const usedLiters = this.props.litersToBeUsed[offer.product.id] || 0;
      const actualLiters = offer.liters_units <= usedLiters ?  0 : offer.liters_units - usedLiters;
      const price = this.getPriceForOffer(offer, paymentMethod);
      return (sum = sum + ((actualLiters * price) || 0));
    }, 0);
    const totalCredits = credits.reduce((sum, credit) => ( sum = sum + ( credit.value || 0)), 0) + bankBalance;
    const total = Math.max(0, totalPayments - totalCredits);
    return { totalCredits, total };
  }

  getPriceForOffer(offer, paymentMethod =this.props.paymentMethod) {
    return paymentMethod === 'credit' ? offer.price_credit : offer.price;
  }

  getTotalForOffer(offer, paymentMethod =this.props.paymentMethod) {
    return this.getPriceForOffer(offer, paymentMethod) * offer.liters_units;
  }

  handleOpenDialogConfirmation = () => {
    this.setState({ dialogConfirmationOpen: true });
  };

  handleOnConfirmation = () => {
    this.setState({ loading: true }, () => {
      const credit_ids = this.props.credits.map((c) => c.id);

      const products = this.props.offers.reduce((acc, offer) => {
        const { liters_units, product } = offer;
        const quantity = (acc[product.id] || 0) + liters_units;
        acc[product.id] = { quantity, name: product.name };
        return acc;
      }, {});

      const data = { products, credit_ids, payment_method: this.props.paymentMethod };

      axios.post(this.props.confirmationPath, data).then((res) => {
        this.setState({ loading: false, dialogConfirmationOpen: false, error: '' });
        this.props.handleReservationConfirmation(res.data.transaction_validator);
        this.props.history.push(`${this.props.basePath}/summary`);
      }).catch((err) => {
        this.setState({ loading: false, error: err.message });
        console.log('Houve um erro durante o processamento', err);
      });
    });
  };

  handleCloseDialogConfirmation = () => {
    this.setState({ dialogConfirmationOpen: false });
  };

  renderOffersSelectedsHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Produtos</TableCell>
          <TableCell numeric>Quant.</TableCell>
          <TableCell numeric>Preço</TableCell>
          <TableCell numeric>Valor</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  renderOffersSelectedsRows = () => {
    return this.props.offers
      .sort((offerA, offerB) => offerA.product.position - offerB.product.position)
      .map((offer) => (
        <TableRow id={`offer-selected-${offer.id}`} key={offer.id}>
          <TableCell className="confirmation__product-name">
            { offer.product.name }
          </TableCell>

          <TableCell className="alignright confirmation__product-quantity">
            { `${numeral(offer.liters_units).format('0.000')}` }
          </TableCell>

          <TableCell className="alignright confirmation__product-value">
            { numeral((this.getPriceForOffer(offer))).format('0.000') }
          </TableCell>

          <TableCell className="alignright confirmation__product-value-total">
            { numeral((this.getTotalForOffer(offer))).format('$ 0.00') }
          </TableCell>
        </TableRow>
      ));
  }

  renderFooterInfos = () => {
    return (
      <TableFooter>
        { this.state.totalCredits > 0 &&
            <TableRow className="table-footer-credit">
              <TableCell colSpan="3">
                Creditos
              </TableCell>
              <TableCell className="alignright confirmation__footer-credits-total">
                { numeral(this.state.totalCredits).format('$ 0.00') }
              </TableCell>
            </TableRow>
        }
        <TableRow className="table-footer-total">
          <TableCell colSpan="3">
            Total a pagar
          </TableCell>
          <TableCell className="alignright confirmation__footer-total">
            { numeral(this.state.total).format('$ 0.00') }
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }

  renderOffersSelectedTable = () => {
    return(
      <Table>
        { this.renderOffersSelectedsHeader() }
        <TableBody className="confirmation__main_table">
          { this.renderOffersSelectedsRows() }
        </TableBody>
        { this.renderFooterInfos() }
      </Table>
    );
  }

  renderProcessingPayment = () => {
    return (
      <Fragment>
        <h2 className="mui-dialog_body__title">Processando...</h2>
        <CircularProgress size={80} />
      </Fragment>
    );
  };


  renderTableConfirmation = () => {
    return (
      <div className="reserve">
        { this.renderOffersSelectedTable() }
        <div>
          <button
            disabled={this.state.loading}
            className="button--primary confirm-payment"
            onClick={this.handleOnConfirmation}>
            Fazer cobrança agora
          </button>
        </div>
        <div className="button-back-container back-buttom">
          <Button  href="#back" onClick={this.props.onClose}>
            Voltar
          </Button>
        </div>
      </div>
    );
  }

  getTitle() {
    return this.props.paymentMethod === 'credit'
      ? 'CARTÃO DE CRÉDITO'
      : 'DINHEIRO OU DÉBITO';
  }

  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.open} >
        <DialogTitle className="mui-dialog__title">{this.getTitle()}</DialogTitle>
        <DialogContent className="mui-dialog__body mui-dialog__body-fulll-width">
          { this.props.loading ?
            this.renderProcessingPayment() :
            this.renderTableConfirmation()
          }
        </DialogContent>
      </Dialog>
    );
  }
}
